@use "sass:math";
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';

.wrapper {
    --position: 0;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    overflow: hidden;
    padding: 12px;
    width: 100%;
    height: var(--vh);
    min-height: -webkit-fill-available;
    align-items: center;
    background-color: $color-dark2;
    transition: 0.65s 0.3s ease-in-out;

    &.is_hidden {
        transform: translateY(-100%);
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 12px;
    padding: 12px 8px;
    height: calc(100% - var(--position) * 1px);
    min-height: 10%;
    color: $color-white;
    background-color: $color-dark;
    transition: opacity 0.3s ease-in-out;

    .is_hidden & {
        opacity: 0;
    }
}

.logo {
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 52px;
    line-height: 0.75;
    letter-spacing: -0.06em;
    text-transform: uppercase;

    @include tablet-up {
        font-size: 80px;
    }

    @include desktop-lg {
        font-size: 96px;
    }

    @include mobile {
        font-size: 37px;
    }

    span {
        color: rgba($color-white, 0.4);
        transition: 0.2s ease-out;
    }

    sup {
        top: -0.2em;
    }

    &_active {
        color: $color-white !important;
    }
}


// active state
.preloader.is_visible {
    visibility: visible;
    opacity: 1;
    transition-delay: .4s;
}

// hidden state
.preloader.is_hidden {
    visibility: hidden;
    opacity: 0;
    transition-delay: .4s;
}

@keyframes opacity {

    0%,
    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes logo {
    from {
        // transform: translate(-50%, -50%) scale(0.5);
        transform: scale(0.5);
    }

    to {
        // transform: translate(-50%, -50%) scale(1);
        transform: scale(1);
    }
}

@keyframes fromRight {
    from {
        transform: translateX(-150%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes fromTop {
    from {
        transform: translateY(-150%) rotate(-87.5deg);
    }

    to {
        transform: translateY(0%) rotate(-87.5deg);
    }
}

@keyframes fromTop2 {
    from {
        transform: translateY(-150%);
    }

    to {
        transform: translateY(0%);
    }
}
