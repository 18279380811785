@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 186px;
    padding-bottom: 100px;

    @include desktop {
        padding-top: 192px;
    }

    @include mobile {
        padding-top: 0;
        padding-bottom: 90px;
    }
}

.container {
    --container-width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.globe {
    position: absolute;
    left: 50%;
    top: 0;
    width: 844px;
    height: 844px;
    transform: translateX(-50%);
    color: rgba($color-dark, 0.2);
    pointer-events: none;

    :global(#dark) & {
        color: rgba($color-white, 0.2);
    }

    @include desktop-xl {
        width: 1008px;
        height: 1008px;
    }
}

.title {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    span {
        display: block;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &_1 {
        margin-top: 22px;

        @include tablet-up {
            margin-top: 122px;
            margin-left: 60px;
        }

        @include desktop-xl {
            margin-top: 182px;
        }
    }
}

.card {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: 12px;
    border-radius: 12px;
    padding-top: 100%;
    // height: 180px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    background: $color-grey;

    :global(#dark) & {
        background: $color-dark;
    }

    &:hover {
        color: $color-white;
        background: $color-dark;

        :global(#dark) & {
            color: $color-dark;
            background: $color-grey;
        }
    }

    @include tablet-up {
        font-size: 13px;
        line-height: 14px;
    }

    &__body {
        position: absolute;
        top: 12px;
        bottom: 22px;
        left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    svg {
        width: 24px;
        height: 24px;
    }

    @include desktop-xl {
        height: 240px;
    }
}

.count-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.count {
    font-weight: 300;
    font-size: 184px;
    line-height: 160px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet {
        margin-top: auto;
        margin-bottom: -16px;
        font-size: 134px;
        line-height: 134px;
    }

    @include mobile {
        margin-bottom: 30px;
        font-size: 96px;
        line-height: 68px;
    }

    @include desktop {
        margin-top: -12px;
    }
}

.team {
    &__item {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            font-size: 20px;
            line-height: 20px;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.64);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }

        @include tablet-up {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        @include mobile {
            margin-bottom: 18px;
        }
    }
}

.bottom {
    padding-top: 118px;

    @include tablet {
        padding-top: 238px;
    }

    @include mobile {
        padding-top: 75px;
    }

    @include desktop-xl {
        padding-top: 238px;
    }

    &__text {
        margin-top: -100%;
        transform: translateY(300%);
    }
}
