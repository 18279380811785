@use "sass:math";
@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 186px;
    padding-bottom: 100px;

    @include mobile {
        padding-top: 100px;
        padding-bottom: 92px;
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }

    @include mobile {
        font-size: 12px;
        line-height: 12px;
    }
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

    @include mobile {
        margin-top: 24px;
        font-size: 12px;
        line-height: 12px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
        color: $color-dark;

            :global(#dark) & {
                color: $color-white;
            }
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.dot {
    margin-left: auto;
    margin-right: 6px;
    border-radius: 50%;
    padding: 0;
    width: 8px;
    height: 8px;
    background: $color-dark;

        :global(#dark) & {
            background: $color-white;
        }


    @include tablet-up {
        width: 12px;
        height: 12px;
    }
}

.globe {
    width: 40px;
    height: 23px;
    path:nth-child(1){
        fill:$color-grey;
    }

    path:nth-child(2){
        fill:#7b7c84;
    }

        :global(#dark) & {
                
            path:nth-child(1){
                fill:$color-dark;
            }

            path:nth-child(2){
                fill:$color-white;
            }
                
        }

    @include tablet-up {
        width: 68px;
        height: 40px;
    }

    @include desktop {
        margin-top: 4px;
    }
}

.body {
    margin-top: 20px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba($color-dark, 0.2);

    :global(#dark) & {
        border-top-color: rgba($color-white, 0.2);
    }

    @include tablet {
        margin-top: 32px;
    }

    @include mobile {
        margin-top: 24px;
    }
}

.count {
    margin-top: -4px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

    @include tablet-up {
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        font-size: 48px;
        line-height: 48px;
    }
}

.studio {
    display: block;
    margin-top: -4px;
    max-width: 660px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

    @include tablet-up {
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        font-size: 48px;
        line-height: 48px;
    }

    &__text:last-child {
        display: block;
        max-width: 660px;
        margin-left: calc(percentage(math.div(1.5, 6)) * -1 - 6px);

        @include tablet-up {
            margin-left: calc(percentage(math.div(4, 8)) * -1 - 6px);
        }

        @include desktop {
            margin-left: calc(percentage(math.div(3, 12)) * -1 - 6px);
        }

        @include desktop-lg {
            margin-left: calc(percentage(math.div(2, 7)) * -1 - 6px);
        }
    }

    &__icon {
        display: inline;
        margin-top: 3px;
        margin-left: 12px;
        width: 40px;
        height: 40px;
        transition: $trans-def;

        path:nth-child(1){
          fill:$color-grey;
        }

        path:nth-child(2){
          fill:$color-dark;
        }

            :global(#dark) & {

                path:nth-child(1){
                  fill:$color-dark;
                }

                path:nth-child(2){
                  fill:$color-white;
                }
                
            }

        @include tablet {
            margin-top: -5px;
            margin-bottom: -5px;
        }

        @include mobile {
            margin-bottom: -15px;
        }
    }

    &:hover {
        svg {
            transform: rotate(45deg);
        }
    }
}
