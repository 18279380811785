@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 64px;
    padding-bottom: 10px;
    min-height: var(--vh);
    background-image: url('./images/bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    :global(#dark) & {
        background-image: url('./images/bg_dark.jpg');
    }

    @include retina {
        background-image: url('./images/bg@2x.jpg');

        :global(#dark) & {
            background-image: url('./images/bg_dark@2x.jpg');
        }
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    position: relative;
    overflow: hidden;
    margin: 0;
    margin-left: -5px;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.9);

    :global(#dark) & {
        color: rgba($color-white, 0.9);
    }

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop-lg {
        font-size: 96px;
        line-height: 86px;
    }

    @include desktop-xl {
        line-height: 96px;
    }

    &_2 {
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }

    }

}

.text {
    margin-top: 75px;
    max-width: 250px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        margin-top: 80px;
        max-width: 100%;
        font-size: 20px;
        line-height: 20px;
    }
}

.work {
    position: absolute;
    top: 222px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 192px;
    height: 192px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-align: center;
    text-transform: uppercase;
    color: $color-dark;
    background: linear-gradient(0deg, $color-yellow, $color-yellow), $color-dark;
    backdrop-filter: blur(6px);

    &:hover {
        opacity: 0.8 !important;
    }
}

.bottom {
    margin-top: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 20px;
        line-height: 20px;
    }

    span {
        display: block;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}

.contact,
.social {
    &__link {
        &:hover {
            opacity: 0.6;
        }
    }
}
