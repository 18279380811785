@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';


.with_styled_tags {
    position: relative;

    h1 {
        margin: 56px 0;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vc(60);
        line-height: vc(58);
        letter-spacing: -0.01em;
        // text-align: center;
        text-transform: uppercase;

        @include devices {
            margin: 40px 0;
            font-size: vc(32);
            line-height: vc(36);
        }
    }

    h2 {
        margin: 48px 0;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vc(40);
        line-height: vc(38);
        letter-spacing: -0.01em;
        // text-align: center;
        text-transform: uppercase;

        @include devices {
            margin: 32px 0;
            font-size: vc(28);
            line-height: vc(26);
        }
    }

    h3 {
        margin: 36px 0;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vc(32);
        line-height: vc(30);
        letter-spacing: -0.01em;
        // text-align: center;
        text-transform: uppercase;

        @include devices {
            margin: 24px 0;
            font-size: vc(24);
            line-height: vc(22);
        }
    }

    h4 {
        margin: 32px 0;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vc(24);
        line-height: vc(22);
        letter-spacing: -0.01em;
        // text-align: center;
        text-transform: uppercase;

        @include devices {
            margin: 20px 0;
            font-size: vc(18);
            line-height: vc(16);
        }
    }

    h5 {
        margin: 24px 0;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vc(22);
        font-weight: 700;
        line-height: vc(20);
        letter-spacing: -0.01em;
        // text-align: center;
        text-transform: uppercase;

        @include devices {
            margin: 18px 0;
            font-size: vc(18);
            line-height: vc(16);
        }
    }

    p {
        margin-bottom: 14px;
        font-weight: 400;
        font-size: vc(16);
        line-height: vc(19);
        // text-align: center;
        text-transform: uppercase;

        strong,
        b {
            font-weight: 400;
        }

        @include devices {
            margin-bottom: 12px;
            font-size: vc(9);
            line-height: vc(14);
        }

        @include mobile {
            margin-bottom: 12px;
            font-size: vc(12);
            line-height: vc(16);
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
        transition: $trans-def;
    }

    li {
        margin-bottom: 16px;
        font-size: vw(16);
        line-height: vw(19);

        @include devices {
            font-size: vc(16);
            line-height: vc(19);
        }
    }

    ul {
        margin: 32px 0;
        color: rgba($color-light, 0.8);

        li {
            position: relative;
            padding-left: 44px;

            &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-yellow;
            }

            @include devices {
                padding-left: 28px;
            }
        }

        @include mobile {
            margin: 20px 0;
        }
    }

    ol {
        margin: 24px 0;
        padding-left: 0;
        color: rgba($color-light, 0.8);
        counter-reset: item;

        li {
            padding-left: 0;

            &:before {
                content: counters(item, ".") ". ";
                margin-right: 8px;
                display: inline-block;
                width: 32px;
                counter-increment: item;
            }
        }

        @include mobile {
            margin: 20px 0;
        }
    }

    table {
        width: 100%;
        border: 1px solid rgba($color-dark2, 0.08);
        border-radius: 24px;
        border-spacing: 0;
        font-weight: 400;
        font-size: vw(16);
        line-height: vw(19);

        @include devices {
            border-radius: 16px;
            font-size: vc(16);
            line-height: vc(19);
        }

        @include desktop {
            min-width: 800px;
        }

        @include mobile {
            border-radius: 8px;
        }

        thead {
            border-top-right-radius: 24px;
            border-top-left-radius: 24px;
            padding: 14px 5px;
            text-align: left;
            color: $color-dark;
            background-color: rgba($color-dark, 0.04);

            @include devices {
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
            }

            @include devices {
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
            }
        }

        td,
        th {
            padding: 24px 32px;
        }

        td {
            border-bottom: 1px solid rgba($color-dark2, 0.08);
        }

        tr:not(:last-of-type),
        thead {
            border-bottom: 1px solid rgba($color-dark2, 0.08);

        }

        @include devices {
            th,
            td {
                padding: 16px;
            }
        }
    }
}
