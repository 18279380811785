@use "sass:math";
@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.main {
    overflow: hidden;
}

.wrapper {
    position: relative;
    display: flex;
    padding-top: 8px;
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.subtitle {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        font-size: 13px;
        line-height: 14px;
    }
}

.top {
    position: relative;
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 13px;
        line-height: 14px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.dot {
    margin-left: auto;
    margin-right: 6px;
    border-radius: 50%;
    padding: 0;
    width: 8px;
    height: 8px;
    background: $color-dark;

    :global(#dark) & {
        background: $color-white;
    }

    @include tablet-up {
        width: 12px;
        height: 12px;
    }
}

.body {
    // display: flex;
    // flex-wrap: wrap;
    padding-top: 42px;
    margin-top: 10px;
    // padding-top: 10px;
    border-top: 1px solid rgba($color-dark, 0.2);

    :global(#dark) & {
        border-top-color: rgba($color-white, 0.2);
    }

    @include tablet-up {
        padding-top: 50px;
    }

    @include desktop {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: minmax(1fr, 96px) repeat(9, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 96px;
        // grid-auto-rows: minmax(200px, auto);
        padding: 12px 20px 42px 20px;
    }
}

.work {
    margin: 13px 0 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        margin-top: 24px;
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        position: absolute;
        top: 38px;
        left: 12px;
        margin-bottom: calc(-100% - 12px);
        font-size: 48px;
        line-height: 48px;
    }
}

.card {
    position: relative;
    display: block;
    // flex-direction: column;
    // flex: 0 0 calc(percentage(math.div(3, 15)) - 12px);
    padding-bottom: 24px;

    &__image {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.4s;
        }
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    @include tablet {
        margin-bottom: 40px;
    }

    @include mobile {
        margin-bottom: 18px;
    }

    &__name {
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    @include desktop {
        &:nth-child(1) {
            grid-area: 2 / 1 / 3 / 2;
        }

        &:nth-child(2) {
            grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(3) {
            grid-area: 1 / 3 / 3 / 5;
        }

        &:nth-child(4) {
            grid-area: 1 / 5 / 2 / 6;
            margin-bottom: -134px;
        }

        &:nth-child(5) {
            grid-area: 3 / 1 / 4 / 2;
        }

        &:nth-child(6) {
            grid-area: 3 / 4 / 4 / 5;
        }

        &:nth-child(7) {
            grid-area: 3 / 5 / 4 / 6;
        }

        &:nth-child(8) {
            grid-area: 4 / 2 / 5 / 3;
        }

        &:nth-child(9) {
            grid-area: 4 / 4 / 5 / 5;
        }

        &:nth-child(10) {
            grid-area: 4 / 5 / 5 / 6;
        }

        &:nth-child(11) {
            grid-area: 5 / 1 / 7 / 3;
        }

        &:nth-child(12) {
            grid-area: 6 / 3 / 7 / 4;
        }

        &:nth-child(13) {
            grid-area: 6 / 5 / 7 / 6;
        }

        &:nth-child(14) {
            grid-area: 7 / 3 / 8 / 4;
        }

        &:nth-child(15) {
            grid-area: 7 / 4 / 8 / 5;
        }

        &:nth-child(16) {
            grid-area: 9 / 1 / 10 / 2;
        }

        &:nth-child(17) {
            grid-area: 9 / 2 / 10 / 3;
        }

        &:nth-child(18) {
            grid-area: 8 / 4 / 10 / 6;
        }
    }
}

.cyrcle {
    position: absolute;
    margin-top: 30px;
    border-radius: 50%;
    width: 100%;
    padding-bottom: 100%;
    background: $color-grey;

    :global(#dark) & {
        background: $color-dark;
    }
}

.cube {
    position: absolute;
    margin-top: 30px;
    border-radius: 12px;
    width: 100%;
    padding-bottom: 100%;
    background-color: $color-grey;

    :global(#dark) & {
        background-color: $color-dark;
    }

    &::before {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 12px;
        left: 12px;
        border-radius: 50%;
        padding-bottom: calc(100% - 24px);
        background-color: $color-white;

        :global(#dark) & {
            background-color: $color-dark2;
        }
    }
}

.dot-2 {
    position: absolute;
    left: -34.5%;
    bottom: -26px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid rgba($color-dark, 0.5);

    :global(#dark) & {
        border: 1px solid rgba($color-white, 0.4);
    }

    background-color: transparent;
}

.text {
    margin: auto 0 0;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    span {
        display: block;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &_1 {
        margin-top: 122px;
        margin-left: 56px;

        @include desktop-xl {
            margin-top: 182px;
        }
    }
}
