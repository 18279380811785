@use "sass:math";
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.main {
    overflow: hidden;

    @include tablet-up {
        padding-bottom: 250px;
    }
}

.container {
    --container-width: 100%;
    @extend .container;

    &_other {
        height: auto !important;
    }
}

.hero {
    position: relative;
    display: flex;
    padding-top: 56px;
    padding-bottom: 44px;

    @include tablet-up {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    :global {
        .row {
            height: 100%;
        }
    }
}

.wrapper {
    padding-bottom: 40px;
    color: $color-dark;

    &:not(:last-child) {
        border-bottom: 1px solid rgba($color-dark, 0.2);
    }

    :global(#dark) & {
        border-bottom-color: rgba($color-white, 0.2);
        color: $color-white;
    }

    @include tablet-up {
        padding: 8px;
        padding-bottom: 96px;
    }
}

.body {
    &__left {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &_desktop {
        @include mobile {
            display: none;
        }
    }
}

.title {
    margin: 0;
    margin-left: -3px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -1.92px;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        font-size: 48px;
        line-height: 48px;
    }
}

.subtitle {
    margin-top: 0;
    max-width: 80%;
    font-family: var(--font-family-secondary);
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1.28px;
    text-transform: uppercase;

    @include mobile {
        max-width: 100%;
        font-size: 20px;
        line-height: 20px;
    }
}

.date {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.3px;

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}

.text {
    max-width: 80%;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.3px;

    @include mobile {
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
    }

    &:not(:first-child) {
        margin-top: 32px;

        @include mobile {
            margin-top: 16px;
        }
    }

    &__left {
        max-width: 242px;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;

        @include mobile {
            max-width: 100%;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.list {
    padding-left: 20px;
    list-style: disc;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.3px;

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }

    &:not(:first-child) {
        margin-top: 32px;

        @include mobile {
            margin-top: 16px;
        }
    }

    li {
        list-style: disc;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}

.picture {
    &_mobile {
        display: none;

        @include mobile {
            display: block;
            margin-bottom: 16px;
        }
    }

    &:not(:first-child) {
        margin: 32px 0;

        @include tablet-up {
            margin: 56px 0;
        }

        @include desktop {
            margin: 96px 0;
        }
    }
}

.blockquote {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1.92px;
    text-transform: uppercase;
    margin: 24px 12px;

    @include tablet-up {
        margin: 40px;
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
    }

    @include desktop {
        margin: 64px 126px;
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
    }
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        margin-top: 24px;
        font-size: 12px;
        line-height: 12px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.user {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }

    &__image {
        overflow: hidden;
        margin-right: 12px;
        border-radius: 50%;
        width: 60px;
        height: 60px;

        @include mobile {
            width: 48px;
            height: 48px;
        }
    }

    &__position {
        color: rgba($color-dark, 0.4);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}
.info {
    padding: 10px 0;
    min-height: var(--vh);

    &_other {
        padding: 10px 0;

        @include desktop {
            min-height: var(--vh);
        }
    }

    :local {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: var(--vh);
        }
    }

    &__title {
        margin: 0;
        font-family: var(--font-family-secondary);
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }

    &__link {
        display: flex;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: -0.005em;
        text-transform: uppercase;

        &__icon {
            margin-top: 2px;
            margin-left: 5px;
            width: 10px;
            height: 10px;
            transition: $trans-def;
        }

        &:hover {
            svg {
                transform: translateX(5px);
            }
        }
    }

    &__dot {
        padding: 0;
        margin-left: auto;
        margin-right: 6px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: $color-dark;

        :global(#dark) & {
            background: $color-white;
        }

        @include tablet-up {
            width: 12px;
            height: 12px;
        }
    }

    &__globe {
        margin-top: 4px;
        width: 40px;
        height: 23px;

        path:nth-child(1) {
            fill: $color-grey;
        }

        path:nth-child(2) {
            fill: #7b7c84;
        }

        :global(#dark) & {
            path:nth-child(1) {
                fill: $color-dark;
            }

            path:nth-child(2) {
                fill: $color-white;
            }

        }

        @include tablet-up {
            width: 68px;
            height: 40px;
        }
    }

    &__body {
        align-items: flex-start;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba($color-dark, 0.2);

        :global(#dark) & {
            border-top-color: rgba($color-white, 0.2);
        }

        @include tablet-up {
            margin-top: 10px;
            padding-top: 6px;
        }
    }

    &__name {
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            font-size: 40px;
            line-height: 40px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }

        &__text:last-child {
            display: block;
            max-width: 660px;
            margin-left: calc(percentage(math.div(1.5, 6)) * -1 - 6px);

            @include tablet-up {
                margin-left: calc(percentage(math.div(4, 8)) * -1 - 6px);
            }

            @include desktop {
                margin-left: calc(percentage(math.div(3, 12)) * -1 - 6px);
            }

            @include desktop-lg {
                margin-left: calc(percentage(math.div(3, 15)) * -1 - 6px);
            }
        }
    }

    &__subname {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        padding-bottom: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__bottom {
        margin-top: auto;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        .container_other & {
            margin-top: 24px;

            @include desktop {
                margin-top: auto;
            }
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__social {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }
}
