@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    padding: 12px 0 32px;
    width: 100%;
    overflow: hidden;
    mix-blend-mode: difference;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-white;
    pointer-events: none;
}

.container {
    --container-width: 100%;
    position: relative;
}

.logo {
    z-index: 11;
    font-weight: 800;
    letter-spacing: -0.02em;
    transition: $trans-def;
    pointer-events: auto;

    &:hover {
        opacity: 0.7;
    }
}

.link {
    transition: $trans-def;
    pointer-events: auto;

    &:hover {
        opacity: 0.7;
    }

    &__wrapper {
        margin-right: 48px;
    }
}

.get {
    transition: $trans-def;
    pointer-events: auto;

    &:hover {
        opacity: 0.7;
    }
}

.burger {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    pointer-events: auto;

    svg {
        margin-right: 10px;
        width: 12px;
        height: 12px;
    }
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2400;
    z-index: 499;
    overflow: hidden;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    height: var(--vh);
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.5s;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;

    &_active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;

        .popup__bg {
            transform: rotateZ(0.0001deg);
        }

        .popup__content {
            opacity: 1;
            visibility: visible;
            transition-delay: 0.5s;
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
        background-color: $color-dark2;
        transform: translateY(-100%);
        transform-origin: top center;
        transition: $trans-def;
    }

    &__content {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        padding: vw(140) 0 0;
        width: 100%;
        min-height: var(--vh);
        height: max-content;
        color: $color-light;
        background-color: $color-dark2;
        opacity: 0;
        visibility: hidden;
        transition: $trans-def;

        @include devices {
            flex-direction: column;
            padding-top: vc(110);
            padding-bottom: vc(52);
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: vc(20);
    }

    &__button {
        display: inline-block;
        width: max-content;
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: vw(60);
        line-height: vw(58);
        text-align: center;
        text-transform: uppercase;
        color: rgba($color-light, 0.2);

        &_active {
            color: $color-light;
        }

        &:not(:last-of-type) {
            margin-bottom: vw(30);

            @include devices {
                margin-bottom: vc(13);
            }
        }

        &[disabled] {
            color: rgba($color-dark, 0.2);
        }

        &:hover {
            color: $color-light;
        }

        @include devices {
            font-size: vc(25);
            line-height: vc(24);
            color: $color-light;
        }
    }

    &__disabled {
        margin-top: auto;
    }

    &__text {
        margin: vw(30) auto 0;
        max-width: vw(310);
        font-weight: 400;
        font-size: vw(21);
        line-height: vw(26);
        text-align: center;
        text-transform: uppercase;
        color: $color-dark;
    }
}
