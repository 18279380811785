@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.breadcrumbs {
    display: flex;
    align-items: center;
    margin: 18px 0 18px 12px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-transform: lowercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }
}

.slash {
    margin: 0 4px;
}

.link {
    &:hover {
        color: rgba($color-dark, 0.6);

        :global(#dark) & {
            color: rgba($color-white, 0.6);
        }
    }
}
