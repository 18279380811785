@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-bottom: 12px;

    @include tablet-up {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    @include desktop {
        padding-bottom: 360px;
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include mobile {
        font-size: 12px;
        line-height: 12px;
    }
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        font-size: 12px;
        line-height: 12px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.body {
    margin-top: 12px;
    align-items: flex-start;

    @include tablet-up {
        margin-top: 27px;
        margin-bottom: -40px;
    }

    @include desktop {
        margin-top: 20px;
        margin-bottom: -24px;
    }

    a {
        margin-bottom: 24px;

        @include tablet {
            margin-bottom: 40px;
        }

        @include mobile {
            margin-bottom: 17px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.work {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        margin-bottom: 48px;
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        margin-bottom: 8px;
        font-size: 48px;
        line-height: 48px;
    }

    @include desktop-lg {
        margin-top: -6px;
        margin-bottom: calc(-100% - 12px);
    }
}

.text {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }
}

.author {
    display: flex;
    align-items: center;
    margin-top: 40px;

    &__avatar {
        margin-right: 12px;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background-color: $color-dark;

        :global(#dark) & {
            background-color: $color-white;
        }
    }

    &__name {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.015em;
        }
    }

    &__position {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}
