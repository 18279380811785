@import "../settings/variables";
// Set the grid defaults
// -----------------------------------------------------------------------------
$column-count: 8 !default;
$gap-size: 6px !default;

// Find the width of 1 column, based upon the column count
// -----------------------------------------------------------------------------
@function find_column_width($columns) {
    $column-width: (100% / $columns);

    @return $column-width;
}

// Create a grid container to house the columns
// -----------------------------------------------------------------------------
@mixin grid($nested: false, $gutters: true) {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-left: -$gap-size;
    margin-right: -$gap-size;

    @if $nested {
        flex: 0 1 auto;
    }

    @if $gutters {
        > * {
            padding-left: $gap-size;
            padding-right: $gap-size;
            box-sizing: border-box;
        }
    }
}

// Create a column of the grid
// -----------------------------------------------------------------------------
@mixin column($columns: 0, $name: null) {
    $width: find_column_width(6);
    $flex-basis: $width * $columns;

    // Set the column width on medium screens
    @if ($name == 'md') {
        $width: find_column_width(8);
        $flex-basis: $width * $columns;
        @media screen and (min-width: ($tablet * 1px)) {
            @if ($columns == 0) {
                flex: 1 0 0;
                width: auto;
            }
            @else {
                flex: 0 0 $flex-basis;
                min-width: $flex-basis;
            }
        }
    } // Set the column width on large screens
    @else if ($name == 'lg') {
        $width: find_column_width(12);
        $flex-basis: $width * $columns;
        @media screen and (min-width: ($desktop * 1px)) {
            @if ($columns == 0) {
                flex: 1 0 0;
                width: auto;
            }
            @else {
                flex: 0 0 $flex-basis;
                min-width: $flex-basis;
            }
        }
    } // Set the column width on bigest screens
    @else if ($name == 'xl') {
        $width: find_column_width(15);
        $flex-basis: $width * $columns;
        @media screen and (min-width: ($laptop * 1px)) {
            @if ($columns == 0) {
                flex: 1 0 0;
                width: auto;
            }
            @else {
                flex: 0 0 $flex-basis;
                min-width: $flex-basis;
            }
        }
    } // Setting $columns = 0 will set the column to be auto width and fill available space
    @else {
        @if ($columns == 0) {
            flex: 1 0 0;
            width: auto;
        }
        @else {
            flex: 0 0 $flex-basis;
            min-width: $flex-basis;
        }
    }
}

.row {
    @include grid();
}

.column {
    @include column();
}

// Make some base columns
@for $i from 1 through 6 {
    .col-#{$i} {
        @include column($i);
    }
}

// Make some base columns for medium
@for $i from 1 through 8 {
    .col-md-#{$i} {
        @include column($i, 'md');
    }
}

// Make some base columns for large
@for $i from 1 through 12 {
    .col-lg-#{$i} {
        @include column($i, 'lg');
    }
}

// Make some base columns for large
@for $i from 1 through 15 {
    .col-xl-#{$i} {
        @include column($i, 'xl');
    }
}

.col {
    flex: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

@media screen and (min-width: ($tablet * 1px)) {
    .col-md {
        flex: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .d-md-none {
        display: none;
    }

    .d-md-block {
        display: block;
    }
}
@media screen and (min-width: ($desktop * 1px)) {
    .col-lg {
        flex: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .d-lg-none {
        display: none;
    }

    .d-lg-block {
        display: block;
    }
}
@media screen and (min-width: ($laptop * 1px)) {
    .col-xl {
        flex: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .d-xl-none {
        display: none;
    }

    .d-xl-block {
        display: block;
    }
}
