@use "sass:math";
@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 186px;
    padding-bottom: 100px;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        padding-top: 100px;
        padding-bottom: 92px;
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    margin: 0;
    margin-bottom: 32px;
    font-family: var(--font-family-secondary);
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1.28px;
    text-transform: uppercase;

    @include mobile {
        font-size: 24px;
        line-height: 24px;
    }
}

.body {
    border-top: 1px solid rgba($color-dark, 0.2);
    padding-top: 12px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.3px;

    :global(#dark) & {
        border-top: 1px solid rgba($color-white, 0.2);
    }

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}

.text {
    &:not(:first-child) {
        margin-top: 32px;
    }
}

.list {
    padding-left: 20px;
    list-style: disc;

    &:not(:first-child) {
        margin-top: 32px;
    }

    li {
        list-style: disc;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}

.picture {
    &:not(:first-child) {
        margin: 40px 0;

        @include tablet-up {
            margin: 56px 0;
        }

        @include desktop {
            margin: 96px 0;
        }
    }
}

.blockquote {
    margin: 64px 126px;
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -1.92px;
    text-transform: uppercase;
    margin: 32px;

    @include tablet-up {
        margin: 40px;
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
    }

    @include desktop {
        margin: 64px 126px;
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
    }
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        margin-top: 24px;
        font-size: 12px;
        line-height: 12px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}
