$color-black: #000;
$color-white: #fff;
$color-yellow: #d5ff2f;
$color-dark: #31333f;
$color-dark2: #3e404d;
$color-light: #f4f3f1;
$color-grey: #e3e3e3;

$color-green: #43d854;
$color-red: #e03e1a;
