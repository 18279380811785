@import '../../assets/styles/base/responsive';
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';


button {
    all: unset;
}

.themeSwitcher {
    position: absolute;
    bottom: -32px;
    right: 8px;
    pointer-events: auto;
}

.switchRoot {
    transition: $trans-def;
    cursor: pointer;
    width: 42px;
    height: 25px;
    background-color: white;
    border-radius: 9999px;
    position: relative;
    //box-shadow: 0 2px 10px var(--black-a7);

    &:hover {
        opacity: 0.75;
    }

    &:focus {
        box-shadow: 0 0 0 2px black;
    }

    &[data-state='checked'] {
        background-color: gray;
    }
}

.switchThumb {
    position: relative;
    display: block;
    width: 21px;
    height: 21px;
    background-color: black;
    border-radius: 9999px;
    //box-shadow: 0 2px 2px var(--black-a7);
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;

    &[data-state='checked'] {
        transform: translateX(19px);
        background-color: white;
    }
}
