@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    padding-top: 174px;
    padding-bottom: 100px;

    @include mobile {
        padding: 0;
    }
}

.container {
    --container-width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.picture {
    overflow: hidden;
    border-radius: 14px;
    background-blend-mode: overlay, normal;
    min-height: 1200px;
    background-image: url('./images/picture.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    :global(#dark) & {
        background-image: url('./images/picture_dark.jpg');
    }

    @include retina {
        background-image: url('./images/picture@2x.jpg');

        :global(#dark) & {
            background-image: url('./images/picture_dark@2x.jpg');
        }
    }
}

.title {
    margin: 0;
    margin-left: -12px;
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    white-space: nowrap;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop-lg {
        font-size: 96px;
        line-height: 86px;
    }

    @include desktop-xl {
        line-height: 96px;
    }
}

.top {
    z-index: 2;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 6px;
    flex-wrap: nowrap;

    @include tablet-up {
        margin-top: 60px;
        margin-bottom: 40px;
    }

    @include desktop {
        margin-top: 100px;
        margin-bottom: 60px;
    }
}

.middle {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        font-size: 13px;
        line-height: 14px;
    }
}

.accordion {
    &__count {
        font-family: var(--font-family-secondary);
        font-weight: 300;
        font-size: 52px;
        line-height: 58px;
        letter-spacing: -0.06em;
        text-transform: uppercase;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            font-size: 82px;
            line-height: 80px;
        }

        @include desktop {
            font-size: 94px;
            line-height: 90px;
        }
    }

    &__item {
        width: 100%;
        padding-bottom: 24px;
        border-top: 1px solid rgba($color-dark, 0.2);
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.015em;

        :global(#dark) & {
            border-top: 1px solid rgba($color-white, 0.2);
        }

        @include tablet-up {
            padding-bottom: 24px;
            font-size: 20px;
            line-height: 20px;
        }

        &:not(:last-child) {
            padding-bottom: 45px;

            @include tablet-up {
                padding-bottom: 96px;
            }
        }
    }

    &__button {
        justify-content: space-between;
        padding-top: 10px;
        width: 100%;
        text-align: left;

        @include tablet-up {
            padding-top: 7px;
        }

        p {
            max-width: 226px;

            color: $color-dark;

            :global(#dark) & {
                color: $color-white;
            }
        }
    }

    &__dropdown {
        max-width: 90%;
        color: rgba($color-dark, 0.64);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }

        @include tablet-up {
            max-width: 75%;
        }
    }

    &__icon {
        margin-left: auto;
        border-radius: 50%;
        padding: 8px;
        width: 24px;
        height: 24px;
        color: $color-dark;
        background: $color-grey;

        :global(#dark) & {
            color: $color-white;
            background: $color-dark;
        }

        @include tablet-up {
            padding: 15px;
            width: 40px;
            height: 40px;
        }

        svg {
            transform: rotate(-90deg);
        }
    }
}

.link {
    display: flex;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        font-size: 16px;
        line-height: 16px;
    }

    &__icon {
        margin-top: 4px;
        margin-left: 5px;
        width: 15px;
        height: 15px;
        transition: $trans-def;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}

.link_mini {
    display: flex;
    margin-top: 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include mobile {
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
    }

    &__icon {
        margin-top: 2px;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transition: $trans-def;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
}
