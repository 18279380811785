@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 12px;
    padding-bottom: 100px;

    @include tablet-up {
        padding-bottom: 103px;
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    margin: 0;
    margin-bottom: 12px;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        margin-bottom: 0;
    }

    span {
        display: block;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &_1 {
        margin-top: 122px;

        @include tablet-up {
            margin-left: 60px;
        }

        @include desktop-xl {
            margin-top: 182px;
        }
    }
}

.card {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: 12px;
    border-radius: 12px;
    padding-top: 100%;
    // height: 180px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    background: $color-grey;

    :global(#dark) & {
        background: $color-dark;
    }

    &:hover {
        color: $color-white;
        background: $color-dark;

        :global(#dark) & {
            color: $color-dark;
            background: $color-grey;
        }
    }

    &__body {
        position: absolute;
        top: 12px;
        bottom: 22px;
        left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    svg {
        width: 24px;
        height: 24px;
        color: currentColor;
    }

    @include desktop-xl {
        height: 240px;
    }
}

.slider {
    @include desktop-lg {
        // margin-top: -192px;
    }

    @include desktop-xl {
        // margin-top: -252px;
    }

    &__wrapper {
        border-radius: 12px;
        padding: 12px;
        background: $color-grey;

        :global(#dark) & {
            background: $color-dark;
        }

        @include tablet {
            margin-bottom: 18px;
        }

        @include mobile {
            margin-bottom: 14px;
        }
    }

    &__col {
        position: relative;
    }

    &__buttons {
        display: flex;
        align-items: center;

        @include desktop {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__button {
        position: relative;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: $color-dark;
        background: $color-grey;
        cursor: pointer;

        :global(#dark) & {
            color: $color-white;
            background: $color-dark;
        }

        &:hover {
            color: $color-white;
            background: $color-dark;

            :global(#dark) & {
                color: $color-dark;
                background: $color-grey;
            }
        }

        svg {
            width: 6px;
            height: 10px;
        }

        &_next {
            svg {
                transform: scale(-1);
            }
        }
    }

    &__slide {
        &__image {
            position: relative;
            padding-top: 100%;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-name {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.015em;
            color: $color-dark;

            :global(#dark) & {
                color: $color-white;
            }

            span {
                display: block;
                color: rgba($color-dark, 0.5);

                :global(#dark) & {
                    color: rgba($color-white, 0.4);
                }
            }


            @include tablet-up {
                font-size: 20px;
                line-height: 20px;
            }

            @include desktop {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__name {
            position: absolute;
            left: 50%;
            top: 50%;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.015em;
            color: $color-white;
            transform: translate(-50%, -50%);

            @include tablet-up {
                font-size: 20px;
                line-height: 20px;
            }

            @include desktop {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.bottom {
    position: relative;

    &__text {
        max-width: 130px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            max-width: 160px;
            font-size: 20px;
            line-height: 20px;
        }

        @include mobile {
            margin-top: 18px;
        }

        @include desktop {
            position: absolute;
            bottom: -3px;
            left: 6px;
        }
    }
}

.progressWrapper {
    display: grid;
    height: 100%;
}

.progress {
    margin: auto 0;
    overflow: hidden;
    width: 100%;
    height: 2px;
    background-color: rgba($color-dark, 0.12);

    :global(#dark) & {
        background-color: rgba($color-white, 0.12);
    }

    &__line {
        width: 100%;
        height: 2px;
        background-color: rgba($color-dark, 1);
        transform: scaleX(var(--progress));
        transform-origin: left;

        :global(#dark) & {
            background-color: rgba($color-white, 1);
        }
    }
}
