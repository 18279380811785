@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.wrapper {
    position: relative;
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: $color-grey;

    :global(#dark) & {
        background: $color-dark;
    }

}

.logo {
    &:hover {
        opacity: 0.7 !important;
    }
}

.year {
    color: rgba($color-dark, 0.5);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }
}

.love {
    text-align: right;

    &__dot {
        display: inline-block;
        margin-left: 6px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: $color-dark;

        :global(#dark) & {
            background: $color-white;
        }

        @include tablet-up {
            margin-left: 12px;
            width: 12px;
            height: 12px;
        }
    }
}


.top {
    flex-wrap: nowrap;
    margin-left: -12px;
    padding-bottom: 21px;

    &:hover {
        .work {
            opacity: 1;
        }
    }

    @include tablet-up {
        padding-bottom: 25px;
    }

    @include desktop {
        padding-top: 7px;
        padding-bottom: 5px;
    }
}

.title {
    margin: 0;
    padding: 0;
    margin-bottom: -10px;
    font-weight: 300;
    font-size: 96px;
    line-height: 96px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    white-space: nowrap;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        margin-left: -10px;
        font-size: 134px;
        line-height: 134px;
    }

    @include desktop {
        font-size: 184px;
        line-height: 160px;
    }
}

.basis {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        font-size: 40px;
        line-height: 40px;
    }

    @include desktop {
        margin-top: 6px;
        font-size: 48px;
        line-height: 48px;
    }
}

.text {
    margin-top: 12px;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    span {
        display: block;
    }

    a {
        text-decoration: underline;

        &:hover {
            opacity: 0.6;
        }
    }

    &_1 {
        margin-left: 60px;
    }

    @include tablet-up {
        margin-top: 26px;
    }

    @include desktop {
        margin-top: 38px;
    }

    @include desktop-xl {
        margin-top: 42px;
    }
}

.bottom {
    margin-top: 226px;

    @include tablet-up {
        margin-top: 342px;
    }

    @include desktop {
        margin-top: 283px;
    }

    @include desktop-xl {
        margin-top: 528px;
    }
}

.social {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 20px;
        line-height: 20px;
    }

    &_mail {
        margin-top: 20px;
    }

    &_messages {
        margin-top: 16px;

        @include tablet-up {
            margin-top: 80px;
        }
    }

    &__link {
        &:hover {
            opacity: 0.6;
        }
    }

    &__text {
        color: rgba($color-dark, 0.64);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}

.footer {
    padding-top: 46px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

    @include tablet-up {
        font-size: 13px;
        line-height: 14px;
    }
}

.work {
    position: absolute;
    // top: -(192px / 2);
    // right: 12px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 192px;
    height: 192px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-align: center;
    text-transform: uppercase;
    color: $color-dark;
    background: linear-gradient(0deg, $color-yellow, $color-yellow), $color-dark;
    backdrop-filter: blur(6px);
    transition: all $trans-def, transform 0s;
    transform: translate(var(--x), var(--y)) translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
}
