@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.hero_container {
    --container-width: #{vw(828)};
    @extend .container;

    @include devices {
        --container-width: 100%;
        font-size: 40px;
        line-height: 36px;
    }
}

// .figure_container {
//     position: absolute;
//     left: 50%;
//     top: 0;
//     z-index: 0;
//     width: 100vw;
//     height: 100%;
//     transform: translateX(-50%);
// }

// .figure {
//     position: absolute;
//     left: 50%;
//     top: vw(-139);
//     width: vw(1923);
//     height: vw(1467);
//     transform: translateX(-50%);
//     pointer-events: none;

//     @include tablet {
//         top: vc(-105);
//         width: vc(755);
//         height: vc(705);

//         path {
//             &:not(:first-child) {
//                 stroke: rgba($color-light, 0.2);
//             }
//         }
//     }

//     @include mobile {
//         top: vc(142);
//         width: vc(565);
//         height: vc(425);

//         path {
//             &:not(:first-child) {
//                 stroke: rgba($color-light, 0.2);
//             }
//         }
//     }
// }

.date {
    opacity: 0.64;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    @include devices {
        margin-top: -32px;
        font-size: 16px;
        font-weight: 400;
    }
}

.main {
    position: relative;
    padding-top: 100px;
    padding-bottom: 56px;
    color: $color-dark2;
    background-color: $color-white;

    :global(#dark) & {
                background-color: $color-dark2;
                color: $color-light;
            }
    
    

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: vw(416);
    //     left: 0;
    //     z-index: 4;
    //     width: 100%;
    //     height: 1px;
    //     background-color: rgba($color-light, 0.1);
    //     pointer-events: none;

    //     @include tablet {
    //         top: vc(177);
    //     }

    //     @include mobile {
    //         top: vc(302);
    //     }
    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     z-index: 1;
    //     width: 1px;
    //     height: 100%;
    //     background-color: rgba($color-light, 0.1);
    //     transform: translateX(-50%);
    //     pointer-events: none;
    // }

    @include devices {
        font-size: 16px;
    }

    @include devices {
        padding-bottom: 36px;
    }
}

.main_container {
    --container-width: #{vw(828)};
    @extend .container;
    margin-top: 56px;

    @include devices {
        --container-width: 100%;
        margin-top: 44px;
    }
}

.table {
    margin-top: 110px;
    overflow-x: scroll;
    scrollbar-width: none;


    &::-webkit-scrollbar {
        display: none;
    }

    @include devices {
        margin-top: 44px;
    }
}
