@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';
@import '../../../../assets/styles/mixins/container';

.wrapper {
    position: relative;
    display: flex;
    padding-top: 67px;
    padding-bottom: 237px;

    @include tablet {
        padding-bottom: 430px;
    }

    @include mobile {
        padding-bottom: 184px;
    }

    @include desktop-xl {
        padding-bottom: 417px;
    }
}

.container {
    --container-width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.title {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop-lg {
        font-size: 96px;
        line-height: 86px;
    }

    @include desktop-xl {
        line-height: 96px;
    }
}
