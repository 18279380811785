@use "sass:math";
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.main {
    overflow: hidden;
}

.container {
    --container-width: 100%;
    @extend .container;

    &_other {
        height: auto !important;
    }
}

.hero {
    position: relative;
    display: flex;
    padding-top: 60px;
    padding-bottom: 10px;
    height: var(--vh);
    background: $color-grey;

    :global(#dark) & {
        background: $color-dark;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }

    :global {
        .row {
            height: 100%;
        }
    }
}

.title {
    margin: 0;
    margin-left: -5px;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: $color-white;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop {
        font-size: 96px;
        line-height: 86px;
    }

    &_2 {
        color: rgba($color-white, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}

.subtitle {
    margin: 0;
    margin-left: -5px;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -0.06em;
    text-transform: uppercase;

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop {
        font-size: 96px;
        line-height: 86px;
    }
}

.scroll {
    margin-top: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    span {
        display: block;
        color: rgba($color-white, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }
}

.info {
    padding: 10px 0;
    min-height: var(--vh);

    &_other {
        padding: 10px 0;

        @include desktop {
            min-height: var(--vh);
        }
    }

    :local {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: var(--vh);
        }
    }

    &__title {
        margin: 0;
        font-family: var(--font-family-secondary);
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }

    &__link {
        display: flex;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: -0.005em;
        text-transform: uppercase;

        &__icon {
            margin-top: 2px;
            margin-left: 5px;
            width: 10px;
            height: 10px;
            transition: $trans-def;
        }

        &:hover {
            svg {
                transform: translateX(5px);
            }
        }
    }

    &__dot {
        padding: 0;
        margin-left: auto;
        margin-right: 6px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: $color-dark;

        :global(#dark) & {
            background: $color-white;
        }

        @include tablet-up {
            width: 12px;
            height: 12px;
        }
    }

    &__globe {
        margin-top: 4px;
        width: 40px;
        height: 23px;

        path:nth-child(1) {
            fill: $color-grey;
        }

        path:nth-child(2) {
            fill: #7b7c84;
        }

        :global(#dark) & {
            path:nth-child(1) {
                fill: $color-dark;
            }

            path:nth-child(2) {
                fill: $color-white;
            }

        }

        @include tablet-up {
            width: 68px;
            height: 40px;
        }
    }

    &__body {
        align-items: flex-start;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba($color-dark, 0.2);

        :global(#dark) & {
            border-top-color: rgba($color-white, 0.2);
        }

        @include tablet-up {
            margin-top: 10px;
            padding-top: 6px;
        }
    }

    &__name {
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        transition: 0.3s;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
        
        @include tablet-up {
            font-size: 40px;
            line-height: 40px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }

        &__bottom {
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        transition: 0.3s;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
        
        @include tablet-up {
            font-size: 40px;
            line-height: 40px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }

        &:hover {
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
        }

        &__text {
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            font-size: 40px;
            line-height: 40px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }

        &__dec:last-child {
            display: block;
            max-width: 660px;

            @include tablet-up {
                margin-left: calc(percentage(math.div(4, 8)) * -1 - 6px);
            }

            @include desktop {
                margin-left: calc(percentage(math.div(3, 12)) * -1 - 6px);
            }

            @include desktop-lg {
                margin-left: calc(percentage(math.div(4.5, 15)) * -1 + 6px);
            }
        }
    }
}

    &__subname {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        padding-bottom: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__bottom {
        margin-top: auto;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        .container_other & {
            margin-top: 24px;

            @include desktop {
                margin-top: auto;
            }
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__social {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }
}

.overview {
    position: relative;
    padding: 114px 0;

    &__text {
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        @include tablet-up {
            font-size: 40px;
            line-height: 40px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }

        &__dec:last-child {
            display: block;
            max-width: 660px;

            @include tablet-up {
                margin-left: calc(percentage(math.div(4, 8)) * -1 - 6px);
            }

            @include desktop {
                margin-left: calc(percentage(math.div(3, 12)) * -1 - 6px);
            }

            @include desktop-lg {
                margin-left: calc(percentage(math.div(4.5, 15)) * -1 + 6px);
            }
        }
    }

    &__top {
        margin: 0;
        font-family: var(--font-family-secondary);
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }

    &__name {
        margin: 0;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        span {
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__play {
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
    }
}

.pictures {
    &__image {
        margin-bottom: var(--gap);
        overflow: hidden;
        border-radius: 12px;
        width: 100%;
        background: $color-light;
        text-align: center;

        :global(#dark) & {
            background-color: $color-dark;
        }

        &_small {
            // height: calc(var(--vh) / 2 - var(--gap) / 2);
        }

        &_big {
            // height: calc(var(--vh));
        }

        img {
            margin: auto;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: 0.4s;
        }

        &_next {
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__text {
        max-width: 375px;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }
    }
}

.credits {
    height: calc(var(--vh) - 160px);

    &__social {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        text-transform: uppercase;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }

        a:hover {
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__title {
        margin-left: auto;
        font-weight: 300;
        font-size: 96px;
        line-height: 74px;
        text-align: right;
        letter-spacing: -0.06em;
        text-transform: uppercase;
        color: rgba($color-dark, 0.2);

        :global(#dark) & {
            color: rgba($color-white, 0.2);
        }

        @include tablet {
            font-size: 40px;
            line-height: 26px;
        }

        @include mobile {
            font-size: 40px;
        }

    }
}
