@use "sass:math";
@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';

.wrapper {
    @include tablet {
        height: calc(var(--vh) - 72px - 89px);
    }
}

.title {
    margin: 0;
    margin-left: calc(percentage(math.div(6, 10)) - 56px);
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba($color-dark, 0.5);

                :global(#dark) & {
                    color: rgba($color-white, 0.4);
                }

    span {
        display: block;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &_1 {
        margin-top: 72px;
        margin-left: 60px;

        @include desktop-xl {
            margin-top: 182px;
        }
    }
}

.top {
    display: flex;
    margin: 182px 0 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

    @include tablet {
        position: absolute;
        left: 0;
        top: 0;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        height: 96px;
        padding-top: 72px;
        background-color: $color-grey;

        :global(#dark) & {
            background-color: $color-dark2;
        }
    }
}

.user {
    padding: 0 var(--gap);

    @include desktop {
        position: relative;
        width: percentage(math.div(2, 10));
    }

    span {
        color: rgba($color-dark, 0.5);

                :global(#dark) & {
                    color: rgba($color-white, 0.4);
                }
    }

    &__picture {
        position: absolute;
        top: 44px;
        border-radius: 50%;
        width: 40px;

        @include tablet {
            left: 50%;
            transform: translateX(-50%);
        }

        @include desktop {
            top: calc(100% + 24px);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background: $color-green;
        }
    }
}

.date {
    padding: 0 var(--gap);
    width: percentage(math.div(4, 10));
    color: rgba($color-dark, 0.5);

                :global(#dark) & {
                    color: rgba($color-white, 0.4);
                }
}

.time {
    margin-right: auto;
    padding: 0 var(--gap);
    color: rgba($color-white, 0.4);
}

.you {
    padding: 0 var(--gap);
}

.scroller {
    @include tablet {
        overflow: hidden;
        overflow-y: auto;
        margin-top: 88px;
        height: 100%;
    }
}

.message {
    &__item {
        display: flex;
        margin-top: 7px;
        border-top: 1px solid rgba($color-dark, 0.12);

                :global(#dark) & {
                    border-top: 1px solid rgba($color-white, 0.12);
                }
        padding-top: var(--gap);
        width: 100%;

        @include mobile {
            flex-direction: column;
        }

        &:last-child {
            margin-bottom: 24px;

            @include desktop {
                margin-bottom: 122px;
            }
        }
    }

    &__col {
        &:first-child {
            width: 50%;

            @include mobile {
                width: 75%;
            }

            @include desktop {
                margin-left: calc(percentage(math.div(2, 10)) + var(--gap) / 2);
                width: percentage(math.div(4, 10));
            }
        }

        &:last-child {
            width: 50%;

            @include mobile {
                margin-left: auto;
                width: 75%;
                text-align: right;
            }

            @include desktop {
                width: percentage(math.div(3.5, 10));
            }

            @include desktop-lg {
                width: percentage(math.div(3, 10));
            }
        }
    }

    &__first {
        margin: calc(var(--gap) / -2);
    }

    &__second {
        margin: 24px 0 calc(var(--gap) / -2);

        @include tablet {
            margin: 48px 0 calc(var(--gap) / -2);
            padding-left: calc(var(--gap) / -2);
        }

        @include desktop {
            margin: calc(187px - var(--gap)) calc(var(--gap) / -2) 0;
            padding-left: calc(var(--gap) / -2);
        }
    }

    &__text {
        display: inline-block;
        margin: calc(var(--gap) / 2);
        margin-left: 10px;
        padding: 10px 12px;
        border-radius: var(--gap);
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        text-align: left;
        color: $color-dark;

            :global(#dark) & {
                color: $color-white;
            }
        background-color: $color-grey;

            :global(#dark) & {
                background-color: $color-dark2;
        }

        span {
            color: rgba($color-dark, 0.4);

                :global(#dark) & {
                    color: rgba($color-white, 0.5);
                }
        }

        &_2 {
            border: 1px solid $color-grey;

                :global(#dark) & {
                    border: 1px solid rgba($color-white, 0.12);
                }
            background-color: $color-white;

                :global(#dark) & {
                    background-color: $color-dark2;
                }
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        margin: calc(var(--gap) / 2);
        padding: 4px calc(var(--gap) / 2) 4px var(--gap);
        border: 1px solid $color-grey;

                :global(#dark) & {
                    border: 1px solid rgba($color-white, 0.12);
                }

        border-radius: 50px;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        text-align: left;
        color: $color-dark;

                :global(#dark) & {
                    color: $color-white;
                }
        background-color: $color-white;

                :global(#dark) & {
                    background-color: $color-dark2;
                }

        &:hover {
            color: $color-white;

                :global(#dark) & {
                    color: $color-dark;
                }
            background-color: $color-dark;

                :global(#dark) & {
                    background-color: $color-white;
                }

            .message__icon {
                background-color: $color-white;

                    :global(#dark) & {
                        background-color: $color-dark;
                    }
            }
        }
    }

    &__icon {
        margin-left: var(--gap);
        padding: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: $color-dark;

                    :global(#dark) & {
                        color: $color-white;
                    }
        background-color: $color-white;

                    :global(#dark) & {
                        background-color: $color-dark2;
                    }
    }
}
