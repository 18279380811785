@import '../../../../assets/styles/settings/variables';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/mixins/breakpoints';
@import '../../../../assets/styles/mixins/helpers';
@import '../../../../assets/styles/base/responsive';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 12px;

    @include tablet {
        background: $color-dark2;
        display: none;
    }

    @include desktop {
        position: fixed;
        right: 0;
        margin: 72px 12px 12px 0;
        border-radius: 12px;
        width: calc(percentage(5 / 15) - 12px);
        height: calc(var(--vh) - 84px);
        background: url("./images/bg.jpg") no-repeat center center $color-dark;
        background-size: cover;

        :global(#dark) & {
            background: url("./images/bg_dark.jpg") no-repeat center center $color-dark;
        }

    }
}


.title {
    margin: -6px 0 0;
    width: calc(100% - 68px);
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    span {
        display: block;
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }

    @include mobile {
        font-size: 24px;
        margin: -1px 0 0 10px;
    }

}

.globe {
    width: 68px;
    height: 40px;

    path:nth-child(1) {
        fill: $color-white;
    }

    path:nth-child(2) {
        fill: #7b7c84;
    }

    :global(#dark) & {

        path:nth-child(1) {
            fill: $color-dark;
        }

        path:nth-child(2) {
            fill: $color-white;
        }

    }
}

.form {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    width: 100%;
    overflow: hidden;
    max-height: calc(100% - 100px);

    @include tablet {
        border-radius: 99px;
        background: $color-dark;
        display: none;
    }
}

.field {
    appearance: none;
    resize: none;
    border: 0;
    outline: none;
    padding: 14px 12px;
    padding-right: 54px;
    width: 100%;
    height: 48px;
    min-height: 48px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    background-color: transparent;

    @include desktop {
        padding: 0;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::placeholder {
        color: rgba($color-dark, 0.5);

        :global(#dark) & {
            color: rgba($color-white, 0.4);
        }
    }

}

.button {
    margin-left: auto;
    padding: 13px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: $color-dark;
    background-color: $color-yellow;

    @include desktop {
        width: 40px;
        height: 40px;
    }

    &:hover {
        transform: rotate(45deg);
    }
}
