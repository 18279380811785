@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.main {
    overflow: hidden;
}

.container {
    --container-width: 100%;
    @extend .container;
}

.container_small {
    --container-width: #{vw(828)};
    @extend .container;
}
