@use "sass:math";
@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.main {
    overflow: hidden;
}

.container {
    --container-width: 100%;
    @extend .container;

    &_other {
        height: auto !important;
    }
}

.hero {
    position: relative;
    display: flex;
    padding-top: 60px;
    padding-bottom: 278px;

    @include mobile {
        padding-bottom: 166px;
    }

    @include desktop-xl {
        padding-bottom: 372px;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    :global {
        .row {
            height: 100%;
        }
    }
}

.title {
    margin: 0;
    margin-left: -3px;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    color: $color-dark;

    :global(#dark) & {
        color: $color-white;
    }

    @include tablet-up {
        font-size: 80px;
        line-height: 80px;
    }

    @include desktop-lg {
        font-size: 96px;
        line-height: 86px;
    }

    @include desktop-xl {
        line-height: 96px;
    }
}

.list {
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__image {
        margin-bottom: var(--gap);
        overflow: hidden;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        text-align: center;
        background: $color-light;

        :global(#dark) & {
            background-color: $color-dark;
        }

        img {
            margin: auto;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: 0.4s;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__content {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 32px;
        color: $color-white;
    }

    &__date {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.015em;

        @include tablet-up {
            font-size: 20px;
            line-height: 20px;
        }
    }

    &__title {
        margin: 0;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.04em;
        text-transform: uppercase;

        @include tablet-up {
            font-size: 32px;
            line-height: 32px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 48px;
        }
    }
}

.credits {
    height: calc(var(--vh) - 160px);

    &__social {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: $color-dark;

        :global(#dark) & {
            color: $color-white;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        span {
            display: block;
            color: rgba($color-dark, 0.5);

            :global(#dark) & {
                color: rgba($color-white, 0.4);
            }
        }
    }

    &__title {
        margin-left: auto;
        font-weight: 300;
        font-size: 96px;
        line-height: 96px;
        text-align: right;
        letter-spacing: -0.06em;
        text-transform: uppercase;
        color: rgba($color-dark, 0.2);

        :global(#dark) & {
            color: rgba($color-white, 0.2);
        }

        @include tablet {
            font-size: 60px;
        }

        @include mobile {
            font-size: 40px;
        }

    }
}
