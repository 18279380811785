@font-face {
    font-family: "NeueHaasDisplay";
    src:
        url("../../../../public/fonts/NeueHaasDisplay/NeueHaasDisplay-Light.woff2"),
        url("../../../../public/fonts/NeueHaasDisplay/NeueHaasDisplay-Light.woff");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff2"),
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.woff2"),
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.woff");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff2"),
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.woff2"),
        url("../../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.woff");
    font-weight: 800;
    font-display: swap;
}
