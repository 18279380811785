@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.main {
    overflow: hidden;
    height: var(--vh);
    min-height: var(--vh);
    background-color: $color-white;

        :global(#dark) & {
            background-color: $color-dark;
        }

    @include desktop {
        display: flex;
        height: auto;
    }
}

.container {
    --container-width: 100%;
    @extend .container;
}
