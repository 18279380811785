@import '../../assets/styles/settings/variables';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/mixins/breakpoints';
@import '../../assets/styles/mixins/helpers';
@import '../../assets/styles/base/responsive';
@import '../../assets/styles/mixins/container';

.container {
    --container-width: 100%;
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: var(--vh);

    &::before {
        content: "";
        position: absolute;
        top: vw(368);
        left: 0;
        z-index: 4;
        width: 100%;
        height: 1px;
        background-color: rgba($color-light, 0.1);
        pointer-events: none;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        width: 1px;
        height: 100%;
        background-color: rgba($color-light, 0.1);
        transform: translateX(-50%);
        pointer-events: none;
    }
}

.container {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
}

.figure {
    position: absolute;
    left: 50%;
    top: vw(-189);
    width: vw(1923);
    height: vw(1467);
    transform: translateX(-50%);
    pointer-events: none;
}

.body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: vw(106);
    min-height: var(--vh);
    height: 100%;
}

.logo {
    position: relative;
    z-index: 3;
    margin: vw(82) auto 0;
}

.text {
    position: relative;
    z-index: 3;
    display: flex;
    margin: 0 auto;
    max-width: vw(200);
    font-weight: 400;
    font-size: vw(12);
    line-height: vw(12);
    text-align: center;
    text-transform: uppercase;
    color: rgba($color-dark, 1);

    :global(#dark) & {
        color: rgba($color-white, 1);
    }
}

.link {
    position: relative;
    z-index: 3;
    display: block;
    margin: auto;
    max-width: vw(200);
    font-weight: 400;
    font-size: vw(12);
    line-height: vw(12);
    text-align: center;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }
}

.title {
    position: relative;
    z-index: 2;
    margin: auto auto vw(17);
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: vw(42);
    line-height: vw(40);
    text-align: center;
    text-transform: uppercase;
    color: rgba($color-dark, 0.64);

    :global(#dark) & {
        color: rgba($color-white, 0.4);
    }

}
